import { Component, OnInit } from '@angular/core';
import emailjs, { EmailJSResponseStatus } from 'emailjs-com';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor(private toastr: ToastrService) { }

  ngOnInit() {
  }

  public submitForm(event: Event) {
    event.preventDefault();
    const form: HTMLFormElement = document.querySelector('#contactForm');
    const successMsg: HTMLDivElement = document.querySelector('.success-msg');
    const lang = localStorage.getItem('lang') || 'FR';

    emailjs.sendForm('contact_service', 'contact_form', event.target as HTMLFormElement, 'user_hEjz8aMh65o7kiTvQvAca')
      .then((result: EmailJSResponseStatus) => {
        console.log(result.text);
        if (lang == 'FR') {
          this.toastr.success("Votre message a bien été envoyé.");
        } else {
          this.toastr.success("Your message has been sent successfully.")
        }
      }, (error) => {
        console.log(error.text);
        if (lang == 'FR') {
          this.toastr.error("Il s'est produit une erreur. Raffraichissez la page et réessayez svp.");
        } else {
          this.toastr.error("An error has occured. Please refresh the page and try again.")
        }
      });

      form.reset();
      
  }
}
