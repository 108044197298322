<!-- Start Contact CTA Section -->
<section class="account-create-area">
    <div class="container">
        <div class="account-create-content">
            <h2>{{ 'contact-cta.title' | translate }}</h2>
            <p>{{ 'contact-cta.intro' | translate }}</p>
            <a routerLink="/contact" class="btn btn-primary">{{ 'contact-cta.btn-cta' | translate }}</a>
        </div>
    </div>
</section>
<!-- End Contact CTA Section -->