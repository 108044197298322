<!-- Start Page Title Area -->
<div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>{{ 'our-work.title' | translate }}</h2>
            <p class="text-center">{{ 'our-work.intro' | translate }}</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Work Area -->
<section class="team-area ptb-70">
    <div class="container">
        <div class="text-center section-title">
            <h2>{{ 'our-work.section.title' | translate }}</h2>
            <div class="bar"></div>
        </div>
        
        <div class="">
            <ul class="list-group list-group-horizontal-lg my-2">
                <li class="list-group-item flex-fill w-10">AgyaCCUL</li>
                <li class="list-group-item flex-fill w-10">AZCCUL</li>
                <li class="list-group-item flex-fill w-10">ALOUCCUL</li>
                <li class="list-group-item flex-fill w-10">ANICCUL</li>
                <li class="list-group-item flex-fill w-10">BAFUCCUL</li>
                <li class="list-group-item flex-fill w-10">BACCCUL</li>
                <li class="list-group-item flex-fill w-10">BafCCUL</li>
                <li class="list-group-item flex-fill w-10">BAPCCUL</li>
                <li class="list-group-item flex-fill w-10">BATCCUL</li>
                <li class="list-group-item flex-fill w-10">BaCCUL</li>
            </ul>

            <ul class="list-group list-group-horizontal-lg my-2">
                <li class="list-group-item flex-fill w-10">CARCCUL</li>
                <li class="list-group-item flex-fill w-10">CACCUL</li>
                <li class="list-group-item flex-fill w-10">ChoCCUL</li>
                <li class="list-group-item flex-fill w-10">GuzCCUL</li>
                <li class="list-group-item flex-fill w-10">MBECCUL</li>
                <li class="list-group-item flex-fill w-10">MitaCCUL</li>
                <li class="list-group-item flex-fill w-10">NGOCCUL</li>
                <li class="list-group-item flex-fill w-10">NJICCUL</li>
                <li class="list-group-item flex-fill w-10">NkwenCCUL</li>
                <li class="list-group-item flex-fill w-10">NsaCCUL</li>
            </ul>

            <ul class="list-group list-group-horizontal-lg my-2">
                <li class="list-group-item flex-fill w-10">TaCCUL</li>
                <li class="list-group-item flex-fill w-10">T-CCUL</li>
                <li class="list-group-item flex-fill w-10">TICCUL</li>
                <li class="list-group-item flex-fill w-10">WIDCCUL</li>
                <li class="list-group-item flex-fill w-10">ASCCUL</li>
                <li class="list-group-item flex-fill w-10">BAWCCUL</li>
                <li class="list-group-item flex-fill w-10">BANCCUL</li>
                <li class="list-group-item flex-fill w-10">BEACCUL</li>
                <li class="list-group-item flex-fill w-10">MEDCCUL</li>
                <li class="list-group-item flex-fill w-10">PHECCUL</li>
            </ul>

            <ul class="list-group list-group-horizontal-lg my-2">
                <li class="list-group-item flex-fill w-10">WACCUL</li>
                <li class="list-group-item flex-fill w-10">NSOSCCUL</li>
                <li class="list-group-item flex-fill w-10">NTENACCUL</li>
                <li class="list-group-item flex-fill w-10">BPTCCUL</li>
                <li class="list-group-item flex-fill w-10">BUPCCUL</li>
                <li class="list-group-item flex-fill w-10">TTCCUL</li>
                <li class="list-group-item flex-fill w-10">MCCUL</li>
                <li class="list-group-item flex-fill w-10">ETCCUL</li>
                <li class="list-group-item flex-fill w-10">EECCUL</li>
                <li class="list-group-item flex-fill w-10">MTCCUL</li>
            </ul>

            <ul class="list-group list-group-horizontal-lg my-2">
                <li class="list-group-item flex-fill w-10">NPACCUL</li>
                <li class="list-group-item flex-fill w-10">VCCCUL</li>
                <li class="list-group-item flex-fill w-10">VITOCCUL</li>
                <li class="list-group-item flex-fill w-10">SOWOCCUL</li>
                <li class="list-group-item flex-fill w-10">BECCUL</li>
                <li class="list-group-item flex-fill w-10">CDCHOCCUL</li>
                <li class="list-group-item flex-fill w-10">LUCCUL</li>
                <li class="list-group-item flex-fill w-10">MECCUL</li>
                <li class="list-group-item flex-fill w-10">TBPCCUL</li>
                <li class="list-group-item flex-fill w-10">TUCCUL</li>
            </ul>

            <ul class="list-group list-group-horizontal-lg my-2">
                <li class="list-group-item flex-fill w-10">TCCCU</li>
                <li class="list-group-item flex-fill w-10">TPCCUL</li>
                <li class="list-group-item flex-fill w-10">MSLCS</li>
                <li class="list-group-item flex-fill w-10">BOCCUL</li>
                <li class="list-group-item flex-fill w-10">PMCCUL</li>
                <li class="list-group-item flex-fill w-10">MESLCS</li>
                <li class="list-group-item flex-fill w-10">KUCCCUL</li>
                <li class="list-group-item flex-fill w-10">AWOCCUL</li>
                <li class="list-group-item flex-fill w-10">KUTCCUL</li>
                <li class="list-group-item flex-fill w-10">MboCUP</li>
            </ul>

            <ul class="list-group list-group-horizontal-lg my-2">
                <li class="list-group-item flex-fill w-10">BeCCUL</li>
                <li class="list-group-item flex-fill w-10">TomCCUL</li>
                <li class="list-group-item flex-fill w-10">BSLCCUL</li>
                <li class="list-group-item flex-fill w-10">SERECCUL</li>
                <li class="list-group-item flex-fill w-10">LOCCU</li>
                <li class="list-group-item flex-fill w-10">NECCUL</li>
                <li class="list-group-item flex-fill w-10">MbongeCCUL</li>
                <li class="list-group-item flex-fill w-10">CECPROM DLA</li>
                <li class="list-group-item flex-fill w-10">PECCU</li>
                <li class="list-group-item flex-fill w-10">WWCCUL</li>
            </ul>

            <ul class="list-group list-group-horizontal-lg my-2">
                <li class="list-group-item flex-fill w-10">CPC TSD</li>
                <li class="list-group-item flex-fill w-10">CAPOCOM</li>
                <li class="list-group-item flex-fill w-10">CAPOCOTRAH</li>
                <li class="list-group-item flex-fill w-10">CAPOCOP</li>
                <li class="list-group-item flex-fill w-10">COFED</li>
                <li class="list-group-item flex-fill w-10">WWCCUL</li>
                <li class="list-group-item flex-fill w-10">CECPROM MATURE</li>
                <li class="list-group-item flex-fill w-10">MUDEF</li>
                <li class="list-group-item flex-fill w-10">KCCU</li>
                <li class="list-group-item flex-fill w-10">COOPSECSOTI</li>
            </ul>

            <ul class="list-group list-group-horizontal-lg my-2">
                <li class="list-group-item flex-fill w-10">CPCD</li>
                <li class="list-group-item flex-fill w-10">NITCCUL</li>
                <li class="list-group-item flex-fill w-10">PLATEAU</li>
                <li class="list-group-item flex-fill w-10">NGACCU</li>
                <li class="list-group-item flex-fill w-10">SCECA</li>
                <li class="list-group-item flex-fill w-10">GATOCCU</li>
                <li class="list-group-item flex-fill w-10">CPCK</li>
                <li class="list-group-item flex-fill w-10">CAPCOY</li>
                <li class="list-group-item flex-fill w-10">CPCT</li>
                <li class="list-group-item flex-fill w-10">CPCMOU</li>
            </ul>

            <ul class="list-group list-group-horizontal-lg my-2">
                <li class="list-group-item flex-fill w-10">BATOCCUL</li>
                <li class="list-group-item flex-fill w-10">SCECOC</li>
                <li class="list-group-item flex-fill w-10">SCECEAN</li>
                <li class="list-group-item flex-fill w-10">SOCCOPECBAT</li>
                <li class="list-group-item flex-fill w-10">SCECUDS</li>
                <li class="list-group-item flex-fill w-10">COOPECFO</li>
                <li class="list-group-item flex-fill w-10">SOCECBAN</li>
                <li class="list-group-item flex-fill w-10">CPCMC</li>
                <li class="list-group-item flex-fill w-10">SOCECBA</li>
                <li class="list-group-item flex-fill w-10">COOPECBAM</li>
            </ul>

            <ul class="list-group list-group-horizontal-lg my-2">
                <li class="list-group-item flex-fill w-10">SOCECPROB</li>
                <li class="list-group-item flex-fill w-10">SOCECMA</li>
                <li class="list-group-item flex-fill w-10">SODEPA</li>
                <li class="list-group-item flex-fill w-10">MMUOCK</li>
                <li class="list-group-item flex-fill w-10">BBACCUL</li>
                <li class="list-group-item flex-fill w-10">KIKCCUL</li>
                <li class="list-group-item flex-fill w-10">KITCCUL</li>
                <li class="list-group-item flex-fill w-10">MBVCCUL</li>
                <li class="list-group-item flex-fill w-10">MECCUL</li>
                <li class="list-group-item flex-fill w-10">SHICCUL</li>
            </ul>

            <ul class="list-group list-group-horizontal-lg my-2">
                <li class="list-group-item flex-fill w-10">MBIACCUL</li>
                <li class="list-group-item flex-fill w-10">JACCUL</li>
                <li class="list-group-item flex-fill w-10">MACCUL</li>
                <li class="list-group-item flex-fill w-10">LITCCUL</li>
                <li class="list-group-item flex-fill w-10">JIACCUL</li>
                <li class="list-group-item flex-fill w-10">FunCCUL</li>
                <li class="list-group-item flex-fill w-10">NjinCCUL</li>
                <li class="list-group-item flex-fill w-10">ACCUL</li>
                <li class="list-group-item flex-fill w-10">MbiCCUL</li>
                <li class="list-group-item flex-fill w-10">AGCCUL</li>
            </ul>

            <ul class="list-group list-group-horizontal-lg my-2">
                <li class="list-group-item flex-fill w-10">MmeCCUL</li>
                <li class="list-group-item flex-fill w-10">NKATCCUL</li>
                <li class="list-group-item flex-fill w-10">NTECCUL</li>
                <li class="list-group-item flex-fill w-10">PWDMCCUL</li>
                <li class="list-group-item flex-fill w-10">MAPCCUL</li>
                <li class="list-group-item flex-fill w-10">MEDCCUL</li>
                <li class="list-group-item flex-fill w-10">FOBADEV</li>
                <li class="list-group-item flex-fill w-10">FIMAC</li>
                <li class="list-group-item flex-fill w-10">CECAEC</li>
                <li class="list-group-item flex-fill w-10">PEXCCUL</li>
            </ul>
        </div>
    </div>
</section>
<!-- End Work Area -->

<app-contact-cta></app-contact-cta>