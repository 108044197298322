    <!-- Start Navbar Area -->
<div class="navbar-area {{layoutClass}}">
    <div class="luvion-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img *ngIf="location != '/demo-3'" src="assets/img/I-TECH__logo-alt.png" alt="logo">
                    <img src="assets/img/I-TECH__logo.png" alt="logo">
                </a>

                <button
                    class="navbar-toggler bg-light"
                    type="button"
                    (click)="toggleNavbar()"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse mean-menu hide" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link">{{ 'header.menu.home-page' | translate }}</a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/company" routerLinkActive="active" class="nav-link">{{ 'header.menu.company-page' | translate }} <i class="fas fa-chevron-down"></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a routerLink="/company" routerLinkActive="active" class="nav-link">{{ 'header.menu.about-us' | translate }}</a>
                                </li>

                                <li class="nav-item">
                                    <a routerLink="/our-team" routerLinkActive="active" class="nav-link">{{ 'header.menu.our-team' | translate }}</a>
                                </li>

                                <li class="nav-item">
                                    <a routerLink="/our-clients" routerLinkActive="active" class="nav-link">{{ 'header.menu.our-work' | translate }}</a>
                                </li>

                                <li class="nav-item">
        <div class="bar"></div>
                                    <a routerLink="/hiring" routerLinkActive="active" class="nav-link">{{ 'header.menu.hiring' | translate }}</a>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/expertise" routerLinkActive="active" class="nav-link">{{ 'header.menu.expertise-page' | translate }} <i class="fas fa-chevron-down"></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a routerLink="/expertise/software-development" routerLinkActive="active" class="nav-link">{{ 'header.menu.software-dev-subpage' | translate }}</a>
                                </li>

                                <li class="nav-item">
                                    <a routerLink="/expertise/audit-&-consulting" routerLinkActive="active" class="nav-link">{{ 'header.menu.audit-cons-subpage' | translate }}</a>
                                </li>

                                <li class="nav-item">
                                    <a routerLink="/expertise/training" routerLinkActive="active" class="nav-link">{{ 'header.menu.training-subpage' | translate }}</a>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/solutions" routerLinkActive="active" class="nav-link">{{ 'header.menu.solutions-page' | translate }} <i class="fas fa-chevron-down"></i></a>
                            <ul class="dropdown-menu d-md-flex">
                                <div class="px-2">
                                    <div class="fw-bold text-uppercase fst-italic soln-title">{{ 'header.menu.core-solutions' | translate }}</div>

                                    <li class="nav-item">
                                        <a routerLink="/solutions/alpha-bank" routerLinkActive="active"  class="nav-link">{{ 'header.menu.alpha-bank-subpage' | translate }}</a>
                                    </li>
    
                                    <li class="nav-item">
                                        <a routerLink="/solutions/alpha-accounting" routerLinkActive="active" class="nav-link">{{ 'header.menu.alpha-acc-subpage' | translate }}</a>
                                    </li>
    
                                    <li class="nav-item">
                                        <a routerLink="/solutions/alpha-microfinance" routerLinkActive="active" class="nav-link">{{ 'header.menu.alpha-microf-subpage' | translate }}</a>
                                    </li>
    
                                    <li class="nav-item">
                                        <a routerLink="/solutions/alpha-monetics" routerLinkActive="active" class="nav-link">{{ 'header.menu.alpha-epayment-subpage' | translate }}</a>
                                    </li>
    
                                    <li class="nav-item">
                                        <a routerLink="/solutions/alpha-payroll-&-hr" routerLinkActive="active" class="nav-link">{{ 'header.menu.alpha-rh-subpage' | translate }}</a>
                                    </li>

                                    <li class="nav-item">
                                        <a routerLink="/solutions/i-collect" routerLinkActive="active" class="nav-link">{{ 'header.menu.i-collect-subpage' | translate }}</a>
                                    </li>
                                </div>
                                <hr>
                                <div class="px-2">
                                    <div class="fw-bold text-uppercase fst-italic soln-title">{{ 'header.menu.added-value-products' | translate }}</div>

                                    <li class="nav-item">
                                        <a routerLink="/solutions/alpha-mobile-banking" routerLinkActive="active" class="nav-link">{{ 'header.menu.alpha-mobile-subpage' | translate }}</a>
                                    </li>
    
                                    <li class="nav-item">
                                        <a routerLink="/solutions/alpha-xpress-bank" routerLinkActive="active" class="nav-link">{{ 'header.menu.alpha-xpress-subpage' | translate }}</a>
                                    </li>

                                    <li class="nav-item">
                                        <a routerLink="/solutions/alpha-web" routerLinkActive="active" class="nav-link">{{ 'header.menu.alpha-web-subpage' | translate }}</a>
                                    </li>

                                    <li class="nav-item">
                                        <a routerLink="/solutions/alpha-webadmin" routerLinkActive="active" class="nav-link">{{ 'header.menu.alpha-webadmin-subpage' | translate }}</a>
                                    </li>

                                    <li class="nav-item">
                                        <a routerLink="/solutions/mycollect" routerLinkActive="active" class="nav-link">{{ 'header.menu.mycollect-subpage' | translate }}</a>
                                    </li>
                                </div>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/blog" routerLinkActive="active" class="nav-link">{{ 'header.menu.blog-page' | translate }}</a>
                        </li>

                        <li class="">
                            <a routerLink="/contact" routerLinkActive="active" class="btn btn-primary fw-bold">{{ 'header.menu.contact-page' | translate }}</a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar Area -->