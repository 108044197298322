import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alpha-accounting',
  templateUrl: './alpha-accounting.component.html',
  styleUrls: ['./alpha-accounting.component.scss']
})
export class AlphaAccountingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
