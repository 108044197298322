<!-- Start Our Numbers Sections -->
<section class="funfacts-area ptb-70 pt-0">
    <div class="container">
        <div class="section-title">
            <h2>{{ 'home.our-numbers.title' | translate }}</h2>
            <div class="bar"></div>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-3 col-md-3 col-6">
                <div class="funfact">
                    <h3><span class="odometer" data-count="110">00</span>+</h3>
                    <p>{{ 'home.our-numbers.box-1' | translate }}</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-3 col-md-3 col-6">
                <div class="funfact">
                    <h3><span class="odometer" data-count="250">00</span>+</h3>
                    <p>{{ 'home.our-numbers.box-2' | translate }}</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-3 col-md-3 col-6">
                <div class="funfact">
                    <h3><span class="odometer" data-count="15">00</span> {{ 'home.our-numbers.box-3.years' | translate }}+</h3>
                    <p>{{ 'home.our-numbers.box-3.text' | translate }}</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-3 col-md-3 col-6">
                <div class="funfact">
                    <h3><span class="odometer" data-count="15">00</span>+</h3>
                    <p>{{ 'home.our-numbers.box-4' | translate }}</p>
                </div>
            </div>
        </div>

        <div class="contact-cta-box">
            <h3>{{ 'home.our-numbers.contact.title' | translate }}</h3>
            <p>{{ 'home.our-numbers.contact.p-1' | translate }}</p>
            <a routerLink="/contact" class="btn btn-primary">{{ 'home.our-numbers.contact.btn-cta' | translate }}</a>
        </div>

        <div class="map-bg">
            <img src="assets/img/map.png" alt="map">
        </div>
    </div>
</section>
<!-- End Our Numbers Section -->