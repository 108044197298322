import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alpha-payroll-hr',
  templateUrl: './alpha-payroll-hr.component.html',
  styleUrls: ['./alpha-payroll-hr.component.scss']
})
export class AlphaPayrollHrComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
