<nav class="topbar navbar navbar-expand-lg bg-dark text-light">
    <div class="container">
        <ul class="topbar__contact navbar-nav col-10 gap-1 mb-2 mb-md-0 text-left">
            <li class="col  d-none d-lg-flex">
                <img src="assets/img/icons/fixed-phone-solid.svg" alt="" class="icon">
                (+237) 243 81 02 96
            </li>
            <li class="col  d-none d-lg-flex">
                <img src="assets/img/icons/phone-alt-solid.svg" alt="" class="icon">
                (+237) 696 61 39 46
            </li>
            <li class="col  d-none d-lg-flex">
                <img src="assets/img/icons/envelope-solid.svg" alt="" class="icon">
                contacts@i-techsarl.com
            </li>
            <li class="col">
              <ul class="social-links">
                <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
              </ul>
            </li>
            
        </ul>
        <div class="topbar__links">
            <div class="dropdown col p-0" title="{{ 'header.topbar.lang-select' | translate }}">
                <a class="btn btn-secondary btn-sm dropdown-toggle p-1 bg-dark" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" (click)="dropdownToggle()">
                  <img src="{{ 'header.topbar.flag' | translate }}" alt="Langue actuelle" class="rounded-circle">
                </a>
              
                <ul class="dropdown-menu bg-dark" aria-labelledby="dropdownMenuLink">
                  <li title="Français" (click)="changeLang('FR')"><a class="dropdown-item p-1">
                    <img src="assets/img/flags/small/fr.svg" alt="Français" class="rounded-circle">
                  </a></li>
                  <li title="English" (click)="changeLang('EN')"><a class="dropdown-item p-1">
                    <img src="assets/img/flags/small/gb.svg" alt="English" class="rounded-circle">
                  </a></li>
                </ul>
            </div>
        </div>
    </div>
</nav> 