<!-- Start Page Title Area -->
<div class="page-title-area item-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>Web Development</h2>
            <p>The Klob story</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area ptb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>Expertise</span>
                    <h2>Analyse et Conception des Applications et Sites Web</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                    <p>Every month they moved their money the old way – which wasted their time and money. So they invented a beautifully simple workaround that became a billion-dollar business.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about-img1.jpg" alt="image">
                    <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="fas fa-play"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Area -->

<!-- Start Services Area -->
<section class="services-area ptb-70 bg-f7fafd">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/2.png" alt="image">

                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="overview-content">
                <div class="content">
                    <h2>Small- to medium-sized businesses</h2>
                    <div class="bar"></div>
                    <p>Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua, lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Easy transfers</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Deposit checks instantly</span></li>
                        <li><span><i class="flaticon-check-mark"></i> A powerful open API</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Coverage around the world</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Business without borders</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Affiliates and partnerships</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->

<!-- Start Services Area -->
<section class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2>Freelancers, entrepreneurs, and sole traders</h2>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Free plan available</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Full data privacy compliance</span></li>
                        <li><span><i class="flaticon-check-mark"></i> 100% transparent costs</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Commitment-free</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Real-time spending overview</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Debit Mastercard included</span></li>
                    </ul>
                </div>
            </div>

            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/1.png" alt="image">

                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->