<!-- Start Page Title Section -->
<div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>{{ 'contact.title' | translate }}</h2>
            <p>{{ 'contact.intro' | translate }}</p>
        </div>
    </div>
</div>
<!-- End Page Title Section -->

<!-- Start Contact Section -->
<section class="contact-area ptb-70">
    <div class="container">
        <div class="section-title">
            <h2>{{ 'contact.section-1.title' | translate }}</h2>
            <div class="bar"></div>
            <p>{{ 'contact.section-1.text' | translate }}</p>
        </div>

        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="contact-info">
                    <ul>
                        <li>
                            <div class="icon">
                                <i class="fas fa-map-marker-alt"></i>
                            </div>
                            <span>{{ 'contact.section-1.info.item-1.title' | translate }}</span>
                            {{ 'contact.section-1.info.item-1.text' | translate }}
                        </li>
                        <li>
                            <div class="icon">
                                <i class="fas fa-envelope"></i>
                            </div>
                            <span>{{ 'contact.section-1.info.item-2.title' | translate }}</span>
                            <a href="mailto:contacts@i-techsarl.com">contacts@i-techsarl.com</a>
                        </li>
                        <li>
                            <div class="icon">
                                <i class="fas fa-phone-volume"></i>
                            </div>
                            <span>{{ 'contact.section-1.info.item-3.title' | translate }}</span>
                            <a href="tel:+237243810296">(+237) 243 81 02 96</a>
                            <a href="tel:+237696613946">(+237) 696 61 39 46</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="contact-form">
                    <form id="contactForm" (submit)="submitForm($event)">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="user_name" id="user_name" required class="form-control" [placeholder]="'contact.section-1.form.name' | translate">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="email" name="user_email" id="user_email" required class="form-control" [placeholder]="'contact.section-1.form.email' | translate">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="user_telephone" id="user_telephone" required data-error="Please enter your number" class="form-control" [placeholder]="'contact.section-1.form.phone_number' | translate">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="subject" id="subject" required class="form-control" [placeholder]="'contact.section-1.form.msg_subject' | translate">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <textarea name="message" class="form-control" id="message" required cols="30" rows="6" [placeholder]="'contact.section-1.form.message' | translate"></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="btn btn-primary">{{ 'contact.section-1.form.submit' | translate }}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div class="bg-map"><img src="assets/img/bg-map.png" alt="image"></div>

</section>
<!-- End Contact Section -->