import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alpha-bank',
  templateUrl: './alpha-bank.component.html',
  styleUrls: ['./alpha-bank.component.scss']
})
export class AlphaBankComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
