<!-- Start Page Title Section -->
<div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>{{ 'solutions.title' | translate }}</h2>
        </div>
    </div>
</div>
<!-- End Page Title Section -->

<!-- Start Solutions Sections -->
<section class="features-area ptb-70 bg-f6f4f8">
    <div class="container">
        <!-- <div class="section-title">
            <h2 class="text-uppercase">{{ 'solutions.section.title' | translate }}</h2>
            <div class="bar"></div>
            <p>{{ 'solutions.section.intro' | translate }}</p>
        </div> -->

        <div>
            <div class="section-title">
                <h2>{{ 'solutions.section.core-solutions' | translate }}</h2>
                <div class="bar"></div>
            </div>

            <div class="row">
                <div class="col-lg-4 col-sm-6 col-md-6 text-center">
                    <a routerLink="/solutions/alpha-bank">
                        <div class="single-features-box">
                            <div class="icon mx-auto">
                                <img src="assets/img/solutions-logos/alphabank_logo.png" alt="ALPHA E-Payments logo">
                            </div>
                            <h3>{{ 'solutions.section.box-1.title' | translate }}</h3>
                            <p>{{ 'solutions.section.box-1.text' | translate }}</p>
                        </div>
                    </a>
                </div>
    
                <div class="col-lg-4 col-sm-6 col-md-6 text-center">
                    <a routerLink="/solutions/alpha-accounting">
                        <div class="single-features-box">
                            <div class="icon bg-f78acb mx-auto">
                                <img src="assets/img/solutions-logos/alphacompta.png" alt="ALPHA E-Payments logo">
                            </div>
                            <h3>{{ 'solutions.section.box-2.title' | translate }}</h3>
                            <p>{{ 'solutions.section.box-2.text' | translate }}</p>
                        </div>
                    </a>
                </div>
    
                <div class="col-lg-4 col-sm-6 col-md-6 text-center">
                    <a routerLink="/solutions/alpha-microfinance">
                        <div class="single-features-box">
                            <div class="icon bg-cdf1d8 mx-auto">
                                <img src="assets/img/solutions-logos/alphabank_logo.png" alt="ALPHA E-Payments logo">
                            </div>
                            <h3>{{ 'solutions.section.box-3.title' | translate }}</h3>
                            <p>{{ 'solutions.section.box-3.text' | translate }}</p>
                        </div>
                    </a>
                </div>
    
                <div class="col-lg-4 col-sm-6 col-md-6 text-center">
                    <a routerLink="/solutions/alpha-monetics">
                        <div class="single-features-box">
                            <div class="icon bg-eb6b3d mx-auto">
                                <img src="assets/img/solutions-logos/alphamonetique_logo.png" alt="ALPHA E-Payments logo">
                            </div>
                            <h3>{{ 'solutions.section.box-4.title' | translate }}</h3>
                            <p>{{ 'solutions.section.box-4.text' | translate }}</p>
                        </div>
                    </a>
                </div>
    
                <div class="col-lg-4 col-sm-6 col-md-6 text-center">
                    <a routerLink="/solutions/alpha-payroll-hr">
                        <div class="single-features-box">
                            <div class="icon mx-auto">
                                <img src="assets/img/solutions-logos/alphapaie_logo.png" alt="ALPHA E-Payments logo">
                            </div>
                            <h3>{{ 'solutions.section.box-5.title' | translate }}</h3>
                            <p>{{ 'solutions.section.box-5.text' | translate }}</p>
                        </div>
                    </a>
                </div>
    
                <div class="col-lg-4 col-sm-6 col-md-6 text-center">
                    <a routerLink="/solutions/i-collect">
                        <div class="single-features-box">
                            <div class="icon bg-c679e3 mx-auto">
                                <img src="assets/img/solutions-logos/alphamobile.png" alt="ALPHA E-Payments logo">
                            </div>
                            <h3>{{ 'solutions.section.box-6.title' | translate }}</h3>
                            <p>{{ 'solutions.section.box-6.text' | translate }}</p>
                        </div>
                    </a>
                </div>
            </div>
        </div>

        <div>
            <div class="section-title">
                <h2>{{ 'solutions.section.added-value-products' | translate }}</h2>
                <div class="bar"></div>
            </div>

            <div class="row">
                <div class="col-lg-4 col-sm-6 col-md-6 text-center">
                    <a routerLink="/solutions/alpha-mobile-banking">
                        <div class="single-features-box">
                            <div class="icon bg-f78acb mx-auto">
                                <img src="assets/img/solutions-logos/alphacompta.png" alt="ALPHA E-Payments logo">
                            </div>
                            <h3>{{ 'solutions.section.box-7.title' | translate }}</h3>
                            <p>{{ 'solutions.section.box-7.text' | translate }}</p>
                        </div>
                    </a>
                </div>
    
                <div class="col-lg-4 col-sm-6 col-md-6 text-center">
                    <a routerLink="/solutions/alpha-xpress-bank">
                        <div class="single-features-box">
                            <div class="icon bg-cdf1d8 mx-auto">
                                <img src="assets/img/solutions-logos/alphabank_logo.png" alt="ALPHA E-Payments logo">
                            </div>
                            <h3>{{ 'solutions.section.box-8.title' | translate }}</h3>
                            <p>{{ 'solutions.section.box-8.text' | translate }}</p>
                        </div>
                    </a>
                </div>
    
                <div class="col-lg-4 col-sm-6 col-md-6 text-center">
                    <a routerLink="/solutions/alpha-web">
                        <div class="single-features-box">
                            <div class="icon bg-eb6b3d mx-auto">
                                <img src="assets/img/solutions-logos/alphamonetique_logo.png" alt="ALPHA E-Payments logo">
                            </div>
                            <h3>{{ 'solutions.section.box-9.title' | translate }}</h3>
                            <p>{{ 'solutions.section.box-9.text' | translate }}</p>
                        </div>
                    </a>
                </div>
    
                <div class="col-lg-4 col-sm-6 col-md-6 text-center">
                    <a routerLink="/solutions/alpha-web-admin">
                        <div class="single-features-box">
                            <div class="icon mx-auto">
                                <img src="assets/img/solutions-logos/alphapaie_logo.png" alt="ALPHA E-Payments logo">
                            </div>
                            <h3>{{ 'solutions.section.box-10.title' | translate }}</h3>
                            <p>{{ 'solutions.section.box-10.text' | translate }}</p>
                        </div>
                    </a>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6 text-center">
                    <a routerLink="/solutions/mycollect">
                        <div class="single-features-box">
                            <div class="icon mx-auto">
                                <img src="assets/img/solutions-logos/alphabank_logo.png" alt="ALPHA E-Payments logo">
                            </div>
                            <h3>{{ 'solutions.section.box-11.title' | translate }}</h3>
                            <p>{{ 'solutions.section.box-11.text' | translate }}</p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Solutions Section -->

<app-contact-cta></app-contact-cta>