<!-- Preloader -->
<div class="preloader">
    <div class="loader">
        <div class="shadow"></div>
        <div class="box"></div>
    </div>
</div>
<!-- End Preloader -->
<app-topbar></app-topbar>
<app-header></app-header>
<router-outlet></router-outlet>
<app-footer></app-footer>