import { Component, OnInit } from '@angular/core';
import BlogArticles from 'src/assets/blogArticles/blogArticles.json';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {
  id: string;
  singleArticle: any;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    let lang = localStorage.getItem('lang') || 'FR';
    let blogArticles;
    if (lang == 'FR') {
      blogArticles = BlogArticles[0];
    } else {
      blogArticles = BlogArticles[1];
    }

    this.id = this.route.snapshot.paramMap.get('id') as string;
    let articleId = Number(this.id);
    this.singleArticle = blogArticles.filter(article => article.id === articleId)[0];
  }

}
