import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alpha-microfinance',
  templateUrl: './alpha-microfinance.component.html',
  styleUrls: ['./alpha-microfinance.component.scss']
})
export class AlphaMicrofinanceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
