import { Component, OnInit } from '@angular/core';
import BlogArticles from 'src/assets/blogArticles/blogArticles.json';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  blogArticles: any;

  constructor() { }

  ngOnInit() {
    const lang = localStorage.getItem('lang') || 'FR';

    if (lang == 'FR') {
      this.blogArticles = BlogArticles[0];
    } else {
      this.blogArticles = BlogArticles[1];
    }
  }

}
