<!-- Start Hero Section -->
<div class="main-banner jarallax" data-jarallax='{"speed": 0.3}'>
    <video autoplay muted loop preload="auto" id="bg-video" oncanplay="this.play()" onloadedmetadata="this.muted = true" poster="assets/img/pages/p-home/hero-bg.png">
        <source src="assets/img/pages/p-home/hero-bg.mp4" type="video/mp4" />
    </video>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div id="heroCarousel" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-indicators">
                      <button type="button" data-bs-target="#heroCarousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                      <button type="button" data-bs-target="#heroCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
                      <button type="button" data-bs-target="#heroCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
                      <button type="button" data-bs-target="#heroCarousel" data-bs-slide-to="3" aria-label="Slide 4"></button>
                    </div>
                    <div class="carousel-inner">
                      <div class="carousel-item active">
                        <div class="main-banner-content">
                            <h1 class="h3">
                                {{ 'home.hero-slider.slide-1.title' | translate }}
                            </h1>
                            <p>
                                {{ 'home.hero-slider.slide-1.text' | translate }} 
                            </p>
                            <a routerLink="/expertise" class="btn btn-primary">
                                {{ 'home.hero-slider.slide-1.cta-btn' | translate }}
                            </a>
                        </div>
                      </div>
                      <div class="carousel-item">
                        <div class="main-banner-content">
                            <h1>
                                {{ 'home.hero-slider.slide-2.title' | translate }}
                            </h1>
                            <p>
                                {{ 'home.hero-slider.slide-2.text' | translate }}
                            </p>
                            <a routerLink="/solutions" class="btn btn-primary">
                                {{ 'home.hero-slider.slide-2.cta-btn' | translate }}
                            </a>
                        </div>
                      </div>
                      <div class="carousel-item">
                        <div class="main-banner-content">
                            <h1>
                                {{ 'home.hero-slider.slide-3.title' | translate }}
                            </h1>
                            <p>
                                {{ 'home.hero-slider.slide-3.text' | translate }}
                            </p>
                            <a routerLink="/expertise" class="btn btn-primary">
                                {{ 'home.hero-slider.slide-3.cta-btn' | translate }}
                            </a>
                        </div>
                      </div>
                      <div class="carousel-item">
                        <div class="main-banner-content">
                            <h1>
                                {{ 'home.hero-slider.slide-4.title' | translate }}
                            </h1>
                            <p>
                                {{ 'home.hero-slider.slide-4.text' | translate }}
                            </p>
                            <a routerLink="/solutions" class="btn btn-primary">
                                {{ 'home.hero-slider.slide-4.cta-btn' | translate }}
                            </a>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Hero Section -->

<!-- Start Expertise Boxes Section -->
<section class="featured-boxes-area">
    <div class="container">
        <div class="featured-boxes-inner">
            <div class="section-title" style="margin-bottom: 0;">
                <h2 class="text-center pt-4">{{ 'home.expertise-boxes.title' | translate }}</h2>
                <div class="bar"></div>
            </div>
            <div class="row ">
                <div class="col-lg-4 col-sm-6 col-md-6 p-0">
                    <div class="single-featured-box">
                        <div class="icon color-fb7756">
                            <img src="assets/img/icons/software.svg" alt="">
                        </div>
                        <h3>{{ 'home.expertise-boxes.box-1.title' | translate }}</h3>
                        <p>{{ 'home.expertise-boxes.box-1.text' | translate }}</p>
                        <a routerLink="/expertise/software-development" class="read-more-btn">{{ 'home.expertise-boxes.cta-btn' | translate }}</a>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6 p-0">
                    <div class="single-featured-box">
                        <div class="icon color-facd60">
                            <img src="assets/img/icons/audit.svg" alt="">
                        </div>
                        <h3>{{ 'home.expertise-boxes.box-2.title' | translate }}</h3>
                        <p>{{ 'home.expertise-boxes.box-2.text' | translate }}</p>
                        <a routerLink="/expertise/audit-&-consulting" class="read-more-btn">{{ 'home.expertise-boxes.cta-btn' | translate }}</a>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6 p-0">
                    <div class="single-featured-box">
                        <div class="icon">
                            <img src="assets/img/icons/training.svg" alt="">
                        </div>
                        <h3>{{ 'home.expertise-boxes.box-3.title' | translate }}</h3>
                        <p>{{ 'home.expertise-boxes.box-3.text' | translate }}</p>
                        <a routerLink="/expertise/training" class="read-more-btn">{{ 'home.expertise-boxes.cta-btn' | translate }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Expertise Boxes Section -->

<!-- About Us Section -->
<section class="about-area ptb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>I-TECH SARL</span>
                    <h2>{{ 'home.about-us.title' | translate }}</h2>
                    <p>{{ 'home.about-us.text' | translate }}</p>
                </div>
                <a routerLink="/company" class="btn btn-primary mt-4">{{ 'home.about-us.cta-btn' | translate }}</a>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/pages/p-home/about-us.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Us Section -->

<!-- Start Solutions Section -->
<section class="features-area ptb-70 bg-f6f4f8">
    <div class="container text-center">
        <div class="section-title">
            <h2>{{ 'home.solutions.title' | translate }}</h2>
            <div class="bar"></div>
            <p>{{ 'home.solutions.intro' | translate }}</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6 text-center">
                <a routerLink="/solutions/alpha-e-payment">
                    <div class="single-features-box">
                        <div class="icon mx-auto">
                            <img src="assets/img/solutions-logos/alphamonetique_logo.png" alt="ALPHA E-Payments logo">
                        </div>
                        <h3>{{ 'home.solutions.box-1.title' | translate }}</h3>
                        <p>{{ 'home.solutions.box-1.text' | translate }}</p>
                    </div>
                </a>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 text-center">
                <a routerLink="/solutions/alpha-accounting">
                    <div class="single-features-box">
                        <div class="icon mx-auto">
                            <img src="assets/img/solutions-logos/alphabank_logo.png" alt="ALPHA Accounting logo">
                        </div>
                        <h3>{{ 'home.solutions.box-2.title' | translate }}</h3>
                        <p>{{ 'home.solutions.box-2.text' | translate }}</p>
                    </div>
                </a>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 text-center">
                <a routerLink="/solutions/alpha-xpress-bank">
                    <div class="single-features-box">
                        <div class="icon mx-auto">
                            <img src="assets/img/solutions-logos/i-collect.png" alt="I-Collect logo">
                        </div>
                        <h3>{{ 'home.solutions.box-3.title' | translate }}</h3>
                        <p>{{ 'home.solutions.box-3.text' | translate }}</p>
                    </div>
                </a>
            </div>
        </div>
        <a routerLink="/solutions" class="btn btn-primary">{{ 'home.solutions.cta-btn' | translate }}</a>
    </div>
</section>
<!-- End Solutions Section -->

<!-- Start News Section -->
<div class="container ptb-70 text-center">
    <div class="section-title">
        <h2>{{ 'home.news.title' | translate }}</h2>
        <div class="bar"></div>
        <p>{{ 'home.news.intro' | translate }}</p>
    </div>
    <div class="row">
        <div class="col-lg-4 col-md-6" *ngFor="let post of blogArticles">
            <div class="single-blog-post">
                <div class="blog-image">
                    <a [routerLink]="['/blog-article', post.id]">
                        <img [src]="post.fimage" alt="image">
                    </a>
                    <div class="date">
                        <i class="far fa-calendar-alt"></i> {{ post.date | date }}
                    </div>
                </div>
                <div class="blog-post-content">
                    <h3 class="post__title"><a [routerLink]="['/blog-article', post.id]">{{ post.title }}</a></h3>
                    <span>par <a>{{ post.author }}</a></span>
                    <div [innerHtml]="(post.content | slice:0:200)+'...'"></div>
                    <a [routerLink]="['/blog-article', post.id]" class="read-more-btn">{{ 'home.news.article-cta-btn' | translate }} <i class="fas fa-arrow-right"></i></a>
                </div>
            </div>
        </div>
    </div>
    <a routerLink="/blog" class="btn btn-primary">{{ 'home.news.blog-cta-btn' | translate }}</a>
</div>
<!-- Ends News Section -->

<!-- Start Partners Section -->

<div class="margin">
    <div class="section-title">
        <h2>{{ 'home.partners.title' | translate }}</h2>
        <div class="bar"></div>
        <p>{{ 'home.partners.intro' | translate }}</p>
    </div>
    <div class="container">
        <div class="our-partners">
            <div class="row align-items-center">
                <div class="col-lg-2 col-sm-2 col-md-2 col-6">
                    <a href="#">
                        <img src="assets/img/partners/desjardins-logo.png" alt="Desjardins Logo">
                        <img src="assets/img/partners/desjardins-logo.png" alt="Desjardins Logo">
                    </a>
                </div>

                <div class="col-lg-2 col-sm-2 col-md-2 col-6">
                    <a href="#">
                        <img src="assets/img/partners/pether-logo.png" alt="PETHER Logo">
                        <img src="assets/img/partners/pether-logo.png" alt="PETHER Logo">
                    </a>
                </div>

                <div class="col-lg-2 col-sm-2 col-md-2 col-6">
                    <a href="#">
                        <img src="assets/img/partners/camccul-logo.png" alt="CamCCUL Logo">
                        <img src="assets/img/partners/camccul-logo.png" alt="CamCCUL Logo">
                    </a>
                </div>

                <div class="col-lg-2 col-sm-2 col-md-2 col-6">
                    <a href="#">
                        <img src="assets/img/partners/adwapay-logo.png" alt="AdwaPay Logo">
                        <img src="assets/img/partners/adwapay-logo.png" alt="AdwaPay Logo">
                    </a>
                </div>

                <div class="col-lg-2 col-sm-2 col-md-2 col-6">
                    <a href="#">
                        <img src="assets/img/partners/omoa-logo.png" alt="OMOA Logo">
                        <img src="assets/img/partners/omoa-logo.png" alt="OMOA Logo">
                    </a>
                </div>

                <div class="col-lg-2 col-sm-2 col-md-2 col-6">
                    <a href="#">
                        <img src="assets/img/partners/contabo-logo.png" alt="OMOA Logo">
                        <img src="assets/img/partners/contabo-logo.png" alt="OMOA Logo">
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Partners Section -->

<app-our-numbers></app-our-numbers>

<app-our-clients></app-our-clients>

<app-client-logos></app-client-logos>

<app-newsletter-popup></app-newsletter-popup>