<!-- Start App Download Area -->
<section class="app-download-area bg-f7fafd">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="app-image">
                    <div class="main-image">
                        <img src="assets/img/mobile-app1.png" class="wow fadeInLeft" data-wow-delay="0.6s" alt="image">
                        <img src="assets/img/mobile-app2.png" class="wow fadeInUp" data-wow-delay="0.9s" alt="image">
                    </div>

                    <div class="main-mobile-image">
                        <img src="assets/img/main-mobile.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="image">
                    </div>

                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="app-download-content">
                    <h2>Découvrez notre solution de collecte journalière I&#8209;COLLECT</h2>
                    <div class="bar"></div>
                    <p>Il est désormais possible de faire enregistrer la collecte journalière directement à partir de votre smartphone Android !</p>
                    <div class="btn-box">
                        <a href="/solutions/i-collect" class="btn btn-primary mb-1 me-2" target="_blank">
                            <!-- <i class="flaticon-apple"></i> -->
                            En Savoir Plus
                        </a>
                        <a href="#" class="btn btn-primary mb-1" target="_blank">
                            <i class="flaticon-play-store"></i>
                            Téléchargez de
                            <span>Google play</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End App Download Area -->