import { Component, OnInit } from '@angular/core';
import { Modal } from 'bootstrap';

@Component({
  selector: 'app-newsletter-popup',
  templateUrl: './newsletter-popup.component.html',
  styleUrls: ['./newsletter-popup.component.scss']
})
export class NewsletterPopupComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
    document.onreadystatechange = () => {
      setTimeout(() => {
        const myModal = new Modal(document.getElementById("exampleModal"), {});
        myModal.show();
      }, 3000)
    };
  }

}
