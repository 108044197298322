<!-- Start Page Title Area -->
<div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>{{ 'mycollect.title' | translate }}</h2>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area ptb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>{{ 'mycollect.overview.title' | translate }}</span>
                    <p>{{ 'mycollect.overview.p-1' | translate }}</p>
                    <p>{{ 'mycollect.overview.p-2' | translate }}</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/pages/p-solutions/sp-bank/alpha-bank.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Area -->

<!-- Start Features Area -->
<section class="features-area ptb-70 bg-f6f4f8">
    <div class="container">
        <div class="section-title">
            <h2>{{ 'mycollect.key-features.title' | translate }}</h2>
            <div class="bar"></div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6 text-center">
                <div class="single-features-box">
                    <div class="icon mx-auto">
                        <img src="assets/img/icons/standards.svg" alt="">
                    </div>
                    <p>{{ 'mycollect.key-features.list.item-1' | translate }}</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 text-center">
                <div class="single-features-box">
                    <div class="icon mx-auto">
                        <img src="assets/img/icons/bank.svg" alt="">
                    </div>
                    <p>{{ 'mycollect.key-features.list.item-2' | translate }}</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 text-center">
                <div class="single-features-box">
                    <div class="icon mx-auto">
                        <img src="assets/img/icons/evolution.svg" alt="">
                    </div>
                    <p>{{ 'mycollect.key-features.list.item-3' | translate }}</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Features Area -->

<!-- Start Services Area -->
<section class="services-area ptb-70 bg-f7fafd">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/pages/p-solutions/advantages.jpg" alt="image">

                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="overview-content">
                <div class="content">
                    <h2>{{ 'mycollect.advantages.title' | translate }}</h2>
                    <div class="bar"></div>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> {{ 'mycollect.advantages.list.item-1' | translate }}</span></li>
                        <li><span><i class="flaticon-check-mark"></i> {{ 'mycollect.advantages.list.item-2' | translate }}</span></li>
                        <li><span><i class="flaticon-check-mark"></i> {{ 'mycollect.advantages.list.item-3' | translate }}</span></li>
                        <li><span><i class="flaticon-check-mark"></i> {{ 'mycollect.advantages.list.item-4' | translate }}</span></li>
                        <li><span><i class="flaticon-check-mark"></i> {{ 'mycollect.advantages.list.item-5' | translate }}</span></li>
                        <li><span><i class="flaticon-check-mark"></i> {{ 'mycollect.advantages.list.item-6' | translate }}</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->

<!-- Start Invoicing Area -->
<section class="invoicing-area ptb-70 bg-f6f4f8">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="invoicing-content">
                    <h2>{{ 'mycollect.request-demo.title' | translate }}</h2>
                    <div class="bar"></div>
                    <p>{{ 'mycollect.request-demo.intro' | translate }}</p>
                    <a routerLink="/contact" class="btn btn-primary">{{ 'mycollect.request-demo.btn-cta' | translate }}</a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="invoicing-image">
                    <div class="main-image">
                        <img src="assets/img/invoicing-image/invoicing1.png" class="wow zoomIn" data-wow-delay="0.7s" alt="image">
                        <img src="assets/img/invoicing-image/invoicing2.png" class="wow fadeInLeft" data-wow-delay="1s" alt="image">
                        <img src="assets/img/invoicing-image/invoicing3.png" class="wow fadeInLeft" data-wow-delay="1.3s" alt="image">
                        <img src="assets/img/invoicing-image/invoicing4.png" class="wow fadeInRight" data-wow-delay="1s" alt="image">
                    </div>

                    <div class="main-mobile-image">
                        <img src="assets/img/invoicing-image/main-pic.png" class="wow zoomIn" data-wow-delay="0.7s" alt="image">
                    </div>

                    <div class="circle-image">
                        <img src="assets/img/invoicing-image/circle1.png" alt="image">
                        <img src="assets/img/invoicing-image/circle2.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Invoicing Area -->