import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { SlickCarouselModule } from 'ngx-slick-carousel';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';

import { FeedbackComponent } from './common/feedback/feedback.component';
import { FunfactComponent } from './common/funfact/funfact.component';
import { PartnerComponent } from './common/partner/partner.component';
import { DownloadComponent } from './common/download/download.component';
import { AccountButtonComponent } from './common/account-button/account-button.component';
import { TalkButtonComponent } from './common/talk-button/talk-button.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';

import { BlogComponent } from './pages/blog/blog.component';
import { BlogDetailsComponent } from './pages/blog-details/blog-details.component';

import { ContactComponent } from './pages/contact/contact.component';
import { HomeComponent } from './pages/home/home.component';
import { CompanyComponent } from './pages/company/company.component';
import { ExpertiseComponent } from './pages/expertise/expertise.component';
import { SolutionsComponent } from './pages/solutions/solutions.component';
import { SoftwareDevelopmentComponent } from './pages/expertise/software-development/software-development.component';
import { AuditConsultingComponent } from './pages/expertise/audit-consulting/audit-consulting.component';
import { TrainingComponent } from './pages/expertise/training/training.component';
import { WebDevelopmentComponent } from './pages/expertise/web-development/web-development.component';
import { AlphaBankComponent } from './pages/solutions/alpha-bank/alpha-bank.component';
import { AlphaAccountingComponent } from './pages/solutions/alpha-accounting/alpha-accounting.component';
import { AlphaMicrofinanceComponent } from './pages/solutions/alpha-microfinance/alpha-microfinance.component';
import { AlphaPayrollHrComponent } from './pages/solutions/alpha-payroll-hr/alpha-payroll-hr.component';
import { AlphaMobileBankingComponent } from './pages/solutions/alpha-mobile-banking/alpha-mobile-banking.component';
import { ICollectComponent } from './pages/solutions/i-collect/i-collect.component';
import { TopbarComponent } from './header/topbar/topbar.component';
import { OurTeamComponent } from './pages/our-team/our-team.component';
import { OurWorkComponent } from './pages/our-work/our-work.component';
import { HiringComponent } from './pages/hiring/hiring.component';
import { ContactCtaComponent } from './common/contact-cta/contact-cta.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { OurNumbersComponent } from './common/our-numbers/our-numbers.component';
import { ClientLogosComponent } from './common/client-logos/client-logos.component';
import { OurClientsComponent } from './common/our-clients/our-clients.component';
import { NewsletterPopupComponent } from './pages/home/newsletter-popup/newsletter-popup.component';
import { AlphaXpressBankComponent } from './pages/solutions/alpha-xpress-bank/alpha-xpress-bank.component';
import { AlphaWebComponent } from './pages/solutions/alpha-web/alpha-web.component';
import { AlphaWebadminComponent } from './pages/solutions/alpha-webadmin/alpha-webadmin.component';
import { MycollectComponent } from './pages/solutions/mycollect/mycollect.component';
import { AlphaMoneticsComponent } from './pages/solutions/alpha-monetics/alpha-monetics.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    FeedbackComponent,
    FunfactComponent,
    PartnerComponent,
    DownloadComponent,
    AccountButtonComponent,
    TalkButtonComponent,
    NotFoundComponent,
    BlogComponent,
    BlogDetailsComponent,
    ContactComponent,
    HomeComponent,
    CompanyComponent,
    ExpertiseComponent,
    SolutionsComponent,
    SoftwareDevelopmentComponent,
    AuditConsultingComponent,
    TrainingComponent,
    WebDevelopmentComponent,
    AlphaBankComponent,
    AlphaAccountingComponent,
    AlphaMicrofinanceComponent,
    AlphaPayrollHrComponent,
    AlphaMobileBankingComponent,
    ICollectComponent,
    TopbarComponent,
    OurTeamComponent,
    OurWorkComponent,
    HiringComponent,
    ContactCtaComponent,
    OurNumbersComponent,
    ClientLogosComponent,
    OurClientsComponent,
    NewsletterPopupComponent,
    AlphaXpressBankComponent,
    AlphaWebComponent,
    AlphaWebadminComponent,
    MycollectComponent,
    AlphaMoneticsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SlickCarouselModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: (localStorage.getItem('lang') || "FR"),
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      progressBar: true,
      progressAnimation: 'increasing',
      preventDuplicates: true,
      positionClass: 'toast-bottom-full-width'
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
