<!-- Start Page Title Area -->
<div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>{{ 'expertise.title' | translate }}</h2>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Features Area -->
<section class="features-area ptb-70 bg-f6f4f8">
    <div class="container">
        <div class="section-title">
            <h2>{{ 'expertise.section.title' | translate }}</h2>
            <div class="bar"></div>
            <p>{{ 'expertise.section.intro' | translate }}</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6 text-center">
                <a routerLink="/expertise/software-development">
                    <div class="single-features-box">
                        <div class="icon mx-auto">
                            <img src="assets/img/icons/software.svg" alt="">
                        </div>
                        <h3>{{ 'expertise.section.box-1' | translate }}</h3>
                        <p></p>
                    </div>
                </a>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 text-center">
                <a routerLink="/expertise/audit-&-consulting">
                    <div class="single-features-box">
                        <div class="icon mx-auto">
                            <img src="assets/img/icons/audit.svg" alt="">
                        </div>
                        <h3>{{ 'expertise.section.box-2' | translate }}</h3>
                        <p></p>
                    </div>
                </a>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 text-center">
                <a routerLink="/expertise/training">
                    <div class="single-features-box">
                        <div class="icon mx-auto">
                            <img src="assets/img/icons/training.svg" alt="">
                        </div>
                        <h3>{{ 'expertise.section.box-3' | translate }}</h3>
                        <p></p>
                    </div>
                </a>
            </div>
        </div>
    </div>
</section>
<!-- End Features Area -->

<app-contact-cta></app-contact-cta>