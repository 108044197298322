import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { BlogComponent } from './pages/blog/blog.component';
import { BlogDetailsComponent } from './pages/blog-details/blog-details.component';
import { ContactComponent } from './pages/contact/contact.component';
import { HomeComponent } from './pages/home/home.component';
import { CompanyComponent } from './pages/company/company.component';
import { ExpertiseComponent } from './pages/expertise/expertise.component';
import { SolutionsComponent } from './pages/solutions/solutions.component';
import { SoftwareDevelopmentComponent } from './pages/expertise/software-development/software-development.component';
import { WebDevelopmentComponent } from './pages/expertise/web-development/web-development.component';
import { AuditConsultingComponent } from './pages/expertise/audit-consulting/audit-consulting.component';
import { TrainingComponent } from './pages/expertise/training/training.component';
import { AlphaAccountingComponent } from './pages/solutions/alpha-accounting/alpha-accounting.component';
import { AlphaBankComponent } from './pages/solutions/alpha-bank/alpha-bank.component';
import { AlphaMicrofinanceComponent } from './pages/solutions/alpha-microfinance/alpha-microfinance.component';
import { AlphaMobileBankingComponent } from './pages/solutions/alpha-mobile-banking/alpha-mobile-banking.component';
import { AlphaPayrollHrComponent } from './pages/solutions/alpha-payroll-hr/alpha-payroll-hr.component';
import { ICollectComponent } from './pages/solutions/i-collect/i-collect.component';
import { AlphaXpressBankComponent } from './pages/solutions/alpha-xpress-bank/alpha-xpress-bank.component';
import { OurTeamComponent } from './pages/our-team/our-team.component';
import { OurWorkComponent } from './pages/our-work/our-work.component';
import { HiringComponent } from './pages/hiring/hiring.component';
import { AlphaMoneticsComponent } from './pages/solutions/alpha-monetics/alpha-monetics.component';
import { AlphaWebComponent } from './pages/solutions/alpha-web/alpha-web.component';
import { AlphaWebadminComponent } from './pages/solutions/alpha-webadmin/alpha-webadmin.component';
import { MycollectComponent } from './pages/solutions/mycollect/mycollect.component';

const routes: Routes = [
    {path: '', component: HomeComponent},

    {path: 'company', component: CompanyComponent},
    {path: 'our-team', component: OurTeamComponent},
    {path: 'our-clients', component: OurWorkComponent},
    {path: 'hiring', component: HiringComponent},

    {path: 'expertise', component: ExpertiseComponent},
    {path: 'expertise/software-development', component: SoftwareDevelopmentComponent},
    {path: 'expertise/web-development', component: WebDevelopmentComponent},
    {path: 'expertise/audit-&-consulting', component: AuditConsultingComponent},
    {path: 'expertise/training', component: TrainingComponent},

    {path: 'solutions', component: SolutionsComponent},
    {path: 'solutions/alpha-bank', component: AlphaBankComponent},
    {path: 'solutions/alpha-accounting', component: AlphaAccountingComponent}, 
    {path: 'solutions/alpha-microfinance', component: AlphaMicrofinanceComponent},
    {path: 'solutions/alpha-monetics', component: AlphaMoneticsComponent},
    {path: 'solutions/alpha-payroll-&-hr', component: AlphaPayrollHrComponent},
    {path: 'solutions/i-collect', component: ICollectComponent},
    {path: 'solutions/alpha-mobile-banking', component: AlphaMobileBankingComponent},
    {path: 'solutions/alpha-xpress-bank', component: AlphaXpressBankComponent},
    {path: 'solutions/alpha-web', component: AlphaWebComponent},
    {path: 'solutions/alpha-webadmin', component: AlphaWebadminComponent},
    {path: 'solutions/mycollect', component: MycollectComponent},

    {path: 'blog', component: BlogComponent},
    {path: 'blog-article/:id', component: BlogDetailsComponent},

    {path: 'contact', component: ContactComponent},
    // Here add new pages component

    {path: '**', component: NotFoundComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }