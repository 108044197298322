<!-- Start Client Logos Section -->
<div class="partner-area">
    <div class="container">
        <div class="partner-inner">
            <div class="row align-items-center">
                <div class="col-lg-3 col-sm-4 col-md-3 col-6">
                    <a href="#">
                        <img src="assets/img/clients/client-1.png" alt="partner">
                        <img src="assets/img/clients/client-1.png" alt="partner">
                    </a>
                </div>

                <div class="col-lg-3 col-sm-4 col-md-3 col-6">
                    <a href="#">
                        <img src="assets/img/clients/client-2.png" alt="partner">
                        <img src="assets/img/clients/client-2.png" alt="partner">
                    </a>
                </div>

                <div class="col-lg-3 col-sm-4 col-md-3 col-6">
                    <a href="#">
                        <img src="assets/img/clients/client-3.png" alt="partner">
                        <img src="assets/img/clients/client-3.png" alt="partner">
                    </a>
                </div>

                <div class="col-lg-3 col-sm-4 col-md-3 col-6">
                    <a href="#">
                        <img src="assets/img/clients/client-4.png" alt="partner">
                        <img src="assets/img/clients/client-4.png" alt="partner">
                    </a>
                </div>

                <div class="col-lg-3 col-sm-4 col-md-3 col-6">
                    <a href="#">
                        <img src="assets/img/clients/client-5.png" alt="partner">
                        <img src="assets/img/clients/client-5.png" alt="partner">
                    </a>
                </div>

                <div class="col-lg-3 col-sm-4 col-md-3 col-6">
                    <a href="#">
                        <img src="assets/img/clients/client-6.png" alt="partner">
                        <img src="assets/img/clients/client-6.png" alt="partner">
                    </a>
                </div>

                <div class="col-lg-3 col-sm-4 col-md-3 col-6">
                    <a href="#">
                        <img src="assets/img/clients/client-7.png" alt="partner">
                        <img src="assets/img/clients/client-7.png" alt="partner">
                    </a>
                </div>

                <div class="col-lg-3 col-sm-4 col-md-3 col-6">
                    <a href="#">
                        <img src="assets/img/clients/client-8.png" alt="partner">
                        <img src="assets/img/clients/client-8.png" alt="partner">
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Client Logos Section -->