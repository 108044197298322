import { Component, OnInit } from '@angular/core';
import { Dropdown } from 'bootstrap';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

  lang: string;

  constructor() { }

  ngOnInit(): void {
    this.lang = localStorage.getItem('lang') || 'FR';
  }

  public changeLang(lang: string) {
    localStorage.setItem('lang', lang);
    window.location.reload();
  }

  public dropdownToggle() {
    const dropdownToggle = new Dropdown(document.getElementById('dropdownMenuLink'), {});
    dropdownToggle.toggle();
  }
}
