import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alpha-mobile-banking',
  templateUrl: './alpha-mobile-banking.component.html',
  styleUrls: ['./alpha-mobile-banking.component.scss']
})
export class AlphaMobileBankingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
