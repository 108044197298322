<!-- Start Contact Us Area -->
<section class="account-create-area">
    <div class="container">
        <div class="account-create-content">
            <h2>Voulez-vous en savoir plus sur nous ?</h2>
            <p>Envoyez nous un message, nous sommes toujours disponible pour discuter.</p>
            <a routerLink="/contact" class="btn btn-primary">Contactez-Nous</a>
        </div>
    </div>
</section>
<!-- End Contact Us Area -->