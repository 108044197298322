<!-- Start Footer Section -->
<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/"><img src="assets/img/I-TECH__logo.png" alt="logo"></a>
                    </div>
                    <ul class="social-links">
                        <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget pr-5">
                    <h3>{{ 'footer.col-2.title' | translate }}</h3>

                    <ul class="list">
                        <li><a routerLink="/">{{ 'footer.col-2.list.item-1' | translate }}</a></li>
                        <li><a routerLink="/company">{{ 'footer.col-2.list.item-2' | translate }}</a></li>
                        <li><a routerLink="/expertise">{{ 'footer.col-2.list.item-3' | translate }}</a></li>
                        <li><a routerLink="/solutions">{{ 'footer.col-2.list.item-4' | translate }}</a></li>
                        <li><a routerLink="/blog">{{ 'footer.col-2.list.item-5' | translate }}</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3>{{ 'footer.col-3.title' | translate }}</h3>

                    <ul class="list">
                        <li><a routerLink="/">{{ 'footer.col-3.list.item-1' | translate }}</a></li>
                        <li><a routerLink="/">{{ 'footer.col-3.list.item-2' | translate }}</a></li>
                        <li><a routerLink="/">{{ 'footer.col-3.list.item-3' | translate }}</a></li>
                        <li><a routerLink="/">{{ 'footer.col-3.list.item-4' | translate }}</a></li>
                        <li><a routerLink="/contact">{{ 'footer.col-3.list.item-5' | translate }}</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3>{{ 'footer.col-4.title' | translate }}</h3>

                    <ul class="footer-contact-info">
                        <li class="d-flex d-flex-row align-items-start gap-2">
                            <div class="icon">
                                <i class="fas fa-map-marker-alt"></i>
                            </div>
                            {{ 'footer.col-4.list.item-1' | translate }}
                        </li>
                        <li class="d-flex d-flex-row align-items-start gap-2">
                            <div class="icon">
                                <i class="fas fa-envelope"></i>
                            </div> 
                            contacts@i-techsarl.com                  
                        </li>
                        <li class="d-flex d-flex-row align-items-start gap-2">
                            <div class="icon">
                                <i class="fas fa-phone-volume"></i>
                            </div>
                            (+237) 243 81 02 96 / 696 61 39 46
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <p>Copyright @2021 <a routerLink="/">I-TECH SARL</a>. {{ 'footer.bottom-footer.text' | translate }}</p>
        </div>
    </div>

    <div class="map-image"><img src="assets/img/map.png" alt="map"></div>
</footer>
<!-- End Footer Section -->

<div class="go-top"><i class="fas fa-arrow-up"></i></div>