<!-- Start Page Title Section -->
<div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>{{ 'hiring.title' | translate }}</h2>
            <p class="text-center">{{ 'hiring.intro' | translate }}</p>
        </div>
    </div>
</div>
<!-- End Page Title Section -->

<!-- Start About Section -->
<section class="about-area ptb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>Innovative Technology</span>
                    <h2>{{ 'hiring.about.title' | translate }}</h2>
                    <p>{{ 'hiring.about.p-1' | translate }}</p>
                    <p>{{ 'hiring.about.p-2' | translate }}</p>
                    <p>{{ 'hiring.about.p-3' | translate }}</p>
                    <p>{{ 'hiring.about.p-4' | translate }}</p>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item">{{ 'hiring.about.list.item-2' | translate }}</li>
                        <li class="list-group-item">{{ 'hiring.about.list.item-1' | translate }}</li>
                        <li class="list-group-item">{{ 'hiring.about.list.item-3' | translate }}</li>
                        <li class="list-group-item">{{ 'hiring.about.list.item-4' | translate }}</li>
                    </ul>
                    <p>{{ 'hiring.about.p-5' | translate }}</p>
                    <p [innerHtml]="'hiring.about.p-6' | translate"></p>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/pages/p-company/sp-hiring/employment.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Section -->

<app-contact-cta></app-contact-cta>