<!-- Start Page Title Area -->
<div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>{{ 'blog.title' | translate }}</h2>
            <p>{{ 'blog.intro' | translate }}</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Area -->
<section class="blog-area ptb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let post of blogArticles">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a [routerLink]="['/blog-article', post.id]">
                            <img [src]="post.fimage" alt="image">
                        </a>
                        <div class="date">
                            <i class="far fa-calendar-alt"></i> {{ post.date | date }}
                        </div>
                    </div>
                    <div class="blog-post-content">
                        <h3><a [routerLink]="['/blog-article', post.id]">{{ post.title }}</a></h3>
                        <span>{{ 'blog.by' | translate }} <a>{{ post.author }}</a></span>
                        <div [innerHtml]="(post.content | slice:0:200)+'...'"></div>
                        <a [routerLink]="['/blog-article', post.id]" class="read-more-btn">{{ 'blog.btn-cta' | translate }} <i class="fas fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Blog Area -->

<app-contact-cta></app-contact-cta>