<!-- Start Page Title Section -->
<div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>{{ 'training.title' | translate }}</h2>
        </div>
    </div>
</div>
<!-- End Page Title Section -->

<!-- Start About Section -->
<section class="about-area ptb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>Expertise</span>
                    <h2>{{ 'training.section-1.title' | translate }}</h2>
                    <p>{{ 'training.section-1.p-1' | translate }}</p>
                    <p>{{ 'training.section-1.p-2' | translate }}</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-image" style="margin-top: 30px;">
                    <img src="assets/img/pages/p-expertise/sp-training/training-section-1.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Section -->

<!-- Start Objectives Section -->
<section class="services-area ptb-70 bg-f7fafd">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/pages/p-expertise/sp-training/training-section-2.jpg" alt="image">
                </div>
            </div>

            <div class="overview-content">
                <div class="content">
                    <h2>{{ 'training.section-2.title' | translate }}</h2>
                    <div class="bar"></div>
                    <p>{{ 'training.section-2.p-1' | translate }}</p>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> {{ 'training.section-2.list.item-1' | translate }}</span></li>
                        <li><span><i class="flaticon-check-mark"></i> {{ 'training.section-2.list.item-2' | translate }}</span></li>
                        <li><span><i class="flaticon-check-mark"></i> {{ 'training.section-2.list.item-3' | translate }}</span></li>
                        <li><span><i class="flaticon-check-mark"></i> {{ 'training.section-2.list.item-4' | translate }}</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Objectives Section -->

<!-- Start Offers Section -->
<section class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2>{{ 'training.section-3.title' | translate }}</h2>
                    <div class="bar"></div>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> {{ 'training.section-3.list.item-1' | translate }}</span></li>
                        <li><span><i class="flaticon-check-mark"></i> {{ 'training.section-3.list.item-2' | translate }} </span></li>
                        <li><span><i class="flaticon-check-mark"></i> {{ 'training.section-3.list.item-3' | translate }}</span></li>
                        <li><span><i class="flaticon-check-mark"></i> {{ 'training.section-3.list.item-4' | translate }}</span></li>
                    </ul>
                </div>
            </div>

            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/pages/p-expertise/sp-training/training-section-3.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Offers Section -->

<!-- Start Assets Section -->
<section class="services-area ptb-70 bg-f7fafd">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image" style="margin-left: 20px;">
                    <img src="assets/img/pages/p-expertise/sp-training/training-section-4.jpg" alt="image">
                </div>
            </div>

            <div class="overview-content">
                <div class="content">
                    <h2>{{ 'training.section-4.title' | translate }}</h2>
                    <div class="bar"></div>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> {{ 'training.section-4.list.item-1' | translate }}</span></li>
                        <li><span><i class="flaticon-check-mark"></i> {{ 'training.section-4.list.item-2' | translate }} </span></li>
                        <li><span><i class="flaticon-check-mark"></i> {{ 'training.section-4.list.item-3' | translate }}</span></li>
                        <li><span><i class="flaticon-check-mark"></i> {{ 'training.section-4.list.item-4' | translate }}</span></li>
                        <li><span><i class="flaticon-check-mark"></i> {{ 'training.section-4.list.item-5' | translate }}</span></li>
                        <li><span><i class="flaticon-check-mark"></i> {{ 'training.section-4.list.item-6' | translate }}</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Assets Section -->

<app-contact-cta></app-contact-cta>