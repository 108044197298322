<!-- Start Page Title Section -->
<div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>{{ 'company.title' | translate }}</h2>
            <p class="text-center">I-TECH SARL</p>
        </div>
    </div>
</div>
<!-- End Page Title Section -->

<!-- Start First About Section -->
<section class="about-area ptb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>{{ 'company.section-1.span' | translate }}</span>
                    <h2>Innovative Technology</h2>
                    <p class="fs-6">{{ 'company.section-1.text.p-1' | translate }}</p>
                    <p class="fs-6">{{ 'company.section-1.text.p-2' | translate }}</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/pages/p-company/company-section-1.jpg" alt="image">
                    <!-- <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="fas fa-play"></i></a> -->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End First About Section -->

<!-- Start Second About Section -->
<section class="about-area ptb-70 bg-f7fafd">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/pages/p-company/company-section-2.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <p class="fs-6">{{ 'company.section-2.text.p-1' | translate }}</p>
                    <p class="fs-6">{{ 'company.section-2.text.p-2' | translate }}</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Second About Section -->

<!-- Start Third About Section -->
<section class="about-area ptb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <h2>{{ 'company.section-3.title' | translate }}</h2>
                    <p class="fs-6">{{ 'company.section-3.text.p-1' | translate }}</p>
                    <p class="fs-6">{{ 'company.section-3.text.p-2' | translate }}</p>
                    <ul class="fs-6 list-group list-group-flush">
                            <li class="list-group-item">{{ 'company.section-3.text.list.item-1' | translate }}</li>
                            <li class="list-group-item">{{ 'company.section-3.text.list.item-2' | translate }}</li>
                            <li class="list-group-item">{{ 'company.section-3.text.list.item-3' | translate }}</li>
                            <li class="list-group-item">{{ 'company.section-3.text.list.item-4' | translate }}</li>
                            <li class="list-group-item">{{ 'company.section-3.text.list.item-5' | translate }}</li>
                        </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/pages/p-company/performance.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Third About Section -->

<!-- Start Methodology Section -->
<section class="how-it-works-area ptb-70 bg-f7fafd">
    <div class="container">
        <div class="section-title">
            <h2>{{ 'company.methodology.title' | translate }} </h2>
            <div class="bar"></div>
            <p class="text-center">{{ 'company.methodology.intro' | translate }} </p>
        </div>

        <div class="row">
            <div class="col-lg-6 col-sm-6 col-md-6">
                <div class="single-how-it-works">
                    <img src="assets/img/pages/p-company/dev-planning.jpg" alt="image">
                    <h3>{{ 'company.methodology.box-1.title' | translate }} </h3>
                    <p>{{ 'company.methodology.box-1.text' | translate }}</p>
                </div>
            </div>

            <div class="col-lg-6 col-sm-6 col-md-6">
                <div class="single-how-it-works">
                    <img src="assets/img/pages/p-company/ux.jpg" alt="image">
                    <h3>{{ 'company.methodology.box-2.title' | translate }}</h3>
                    <p>{{ 'company.methodology.box-2.text' | translate }}</p>
                </div>
            </div>

            <div class="col-lg-6 col-sm-6 col-md-6">
                <div class="single-how-it-works">
                    <img src="assets/img/pages/p-company/security.jpg" alt="image">
                    <h3>{{ 'company.methodology.box-3.title' | translate }}</h3>
                    <p>{{ 'company.methodology.box-3.text' | translate }}</p>
                </div>
            </div>

            <div class="col-lg-6 col-sm-6 col-md-6">
                <div class="single-how-it-works">
                    <img src="assets/img/pages/p-company/support.jpg" alt="image">
                    <h3>{{ 'company.methodology.box-4.title' | translate }}</h3>
                    <p>{{ 'company.methodology.box-4.text' | translate }}</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Methodology Section -->

<!-- Start Our Mission Section -->
<section class="about-area ptb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <h2>{{ 'company.mission.title' | translate }}</h2>
                    <p class="fs-5">{{ 'company.mission.text' | translate }}</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/pages/p-company/mission.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Our Mission Section -->

<!-- Start Our Values Section -->
<section class="about-area ptb-70 bg-f7fafd">
    <div class="container">
        <div class="section-title">
            <h2>{{ 'company.values.title' | translate }}</h2>
            <div class="bar"></div>
        </div>
    </div>
    <div class="container">
        <div class="row align-items-center">
            <div class="row">
                <div class="col-lg-6 col-sm-6 col-md-6">
                    <div class="features-box">
                        <div class="icon">
                            <img src="assets/img/icons/innovation.svg" alt="">
                        </div>
                        <h3>{{ 'company.values.box-1.title' | translate }}</h3>
                        <p class="fs-6">{{ 'company.values.box-1.text' | translate }}</p>
                    </div>
                </div>

                <div class="col-lg-6 col-sm-6 col-md-6">
                    <div class="features-box">
                        <div class="icon ">
                            <img src="assets/img/icons/flexibility.svg" alt="">
                        </div>
                        <h3>{{ 'company.values.box-2.title' | translate }}</h3>
                        <p class="fs-6">{{ 'company.values.box-2.text' | translate }}</p>
                    </div>
                </div>

                <div class="col-lg-6 col-sm-6 col-md-6">
                    <div class="features-box">
                        <div class="icon ">
                            <img src="assets/img/icons/dynamism.svg" alt="">
                        </div>
                        <h3>{{ 'company.values.box-3.title' | translate }}</h3>
                        <p class="fs-6">{{ 'company.values.box-3.text' | translate }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Our Values Section -->

<!-- Start Value System Section -->
<section class="features-section ptb-70">
    <div class="container">
        <div class="section-title">
            <h2>{{ 'company.system.title' | translate }}</h2>
            <div class="bar"></div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="features-box-list">
                    <div class="row">
                        <div class="col-lg-12 col-sm-6 col-md-6">
                            <div class="features-box">
                                <div class="icon">
                                    <img src="assets/img/icons/diagnostic.svg" alt="">
                                </div>
                                <p class="fs-6">{{ 'company.system.box-1' | translate }}</p>
                            </div>
                        </div>

                        <div class="col-lg-12 col-sm-6 col-md-6">
                            <div class="features-box">
                                <div class="icon ">
                                    <img src="assets/img/icons/propositions.svg" alt="">
                                </div>
                                <p class="fs-6">{{ 'company.system.box-2' | translate }}</p>
                            </div>
                        </div>

                        <div class="col-lg-12 col-sm-6 col-md-6">
                            <div class="features-box">
                                <div class="icon ">
                                    <img src="assets/img/icons/support.svg" alt="">
                                </div>
                                <p class="fs-6">{{ 'company.system.box-3' | translate }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="features-image">
                    <img src="assets/img/pages/p-company/value-system.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Value System Section -->

<app-contact-cta></app-contact-cta>