import { Component, OnInit } from '@angular/core';
import BlogArticles from 'src/assets/blogArticles/blogArticles.json';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  blogArticles: any;

  constructor() { }

  ngOnInit() {
    let lang = localStorage.getItem('lang') || 'FR';
    if (lang == 'FR') {
      this.blogArticles = BlogArticles[0];
    } else {
      this.blogArticles = BlogArticles[1];
    }
  }

  thumbnailsSlider = {
    speed:300,
    slidesToShow:5,
    slidesToScroll:1,
    cssEase:'linear',
    autoplay: true,
    centerMode:true,
    centerPadding: '0',
    draggable:false,
    focusOnSelect:true,
    asNavFor:".feedback",
    prevArrow:'.client-thumbnails .prev-arrow',
    nextArrow:'.client-thumbnails .next-arrow',
  };
}
