<!-- Start Page Title Area -->
<div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}' *ngIf="singleArticle">
    <div class="container">
        <div class="page-title-content">
            <h2>{{singleArticle.title}}</h2>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Details Area -->
<section class="blog-details-area ptb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12 mx-auto">
                <div class="blog-details">
                    <div class="article-image">
                        <img [src]="singleArticle.fimage" alt="image">
                    </div>

                    <div class="article-content" *ngIf="singleArticle">
                        <div class="entry-meta">
                            <ul>
                                <li><span>Publié le : </span> <a>{{ singleArticle.date | date }}</a></li>
                                <li><span>Publié par : </span> <a>{{ singleArticle.author }}</a></li>
                            </ul>
                        </div>
                        <h3>{{ singleArticle.title }}</h3>
                        <div [innerHtml]="singleArticle.content"></div>
                    </div>

                    <div class="article-footer">
                        <div class="article-tags">
                            <span><i class="fas fa-bookmark"></i></span>
                            <a>Fintech</a>,
                            <a>Banking</a>,
                            <a>IT</a>
                        </div>

                        <div class="article-share">
                            <ul class="social">
                                <li><a  target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a  target="_blank"><i class="fab fa-twitter"></i></a></li>
                                <li><a  target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                                <li><a  target="_blank"><i class="fab fa-instagram"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Blog Details Area -->

<app-contact-cta></app-contact-cta>
