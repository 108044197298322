<!-- Start Fun Facts Area -->
<section class="funfacts-area ptb-70 pt-0">
    <div class="container">
        <div class="section-title">
            <h2>Notre parcours nous rend toujours plus exigeants vis-à-vis de nous-mêmes.</h2>
            <div class="bar"></div>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-3 col-md-3 col-6">
                <div class="funfact">
                    <h3><span class="odometer" data-count="110">00</span>+</h3>
                    <p>Projets</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-3 col-md-3 col-6">
                <div class="funfact">
                    <h3><span class="odometer" data-count="250">00</span>+</h3>
                    <p>Clients</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-3 col-md-3 col-6">
                <div class="funfact">
                    <h3><span class="odometer" data-count="15">00</span> ans+</h3>
                    <p>d'Expérience</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-3 col-md-3 col-6">
                <div class="funfact">
                    <h3><span class="odometer" data-count="15">00</span>+</h3>
                    <p>Collaborateurs</p>
                </div>
            </div>
        </div>

        <div class="contact-cta-box">
            <h3>Avez-vous des questions ?</h3>
            <p>N'hésitez pas à nous contacter.</p>
            <a routerLink="/contact" class="btn btn-primary">Nous Contacter</a>
        </div>

        <div class="map-bg">
            <img src="assets/img/map.png" alt="map">
        </div>
    </div>
</section>
<!-- End Fun Facts Area -->