<!-- Start Page Title Area -->
<div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>{{ 'our-team.title' | translate }}</h2>
            <p class="text-center">{{ 'our-team.intro' | translate }}</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area ptb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>{{ 'our-team.about.span' | translate }}</span>
                    <h2>Innovative Technology</h2>
                    <p>{{ 'our-team.about.text.p-1' | translate }}</p>
                    <p>{{ 'our-team.about.text.p-2' | translate }}</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/pages/p-company/sp-ourteam/ourteam-section-1.jpg" alt="image">
                    <!--<a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="fas fa-play"></i></a>-->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Area -->

<app-contact-cta></app-contact-cta>